<template>
  <v-container>
    <v-row>
      <v-spacer class="hidden-xs-only"></v-spacer>
      <v-col>
        <v-data-table
          hide-default-header
          hide-default-footer
          :headers="headers"
          :items="sudoku.problem"
          justify-center
          color="#dddddd"
        >
          <!-- Having 9 implementations of the same code is silly but my Vue knowledge
         is too limited to get this to work and it will do for now. -->
          <template v-slot:[`item.0`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['0']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["0"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  color="green"
                  v-model="props.item['0']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.1`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['1']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["1"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['1']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.2`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['2']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["2"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['2']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.3`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['3']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["3"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['3']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.4`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['4']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["4"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['4']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.5`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['5']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["5"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['5']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.6`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['6']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["6"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['6']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.7`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['7']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["7"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['7']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.8`]="props">
            <v-edit-dialog
              :return-value.sync="props.item['8']"
              large
              persistent
              @save="save"
              @cancel="cancel"
              @open="open"
            >
              <v-chip color="white">
                {{ props.item["8"] }}
              </v-chip>
              <template v-slot:input>
                <div class="mt-4 title">Update Cell</div>
                <v-text-field
                  v-model="props.item['8']"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-col>
      <v-spacer class="hidden-xs-only"></v-spacer>
    </v-row>
    <v-row>
      <v-spacer :md="5" class="hidden-xs-only"></v-spacer>
      <v-col class="d-flex justify-center">
        <v-btn v-on:click="progress()" depressed>Progress</v-btn>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-btn v-on:click="getNewProblem()" depressed>New Sudoku</v-btn>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-btn
          justify-center
          v-on:click="isCompleteButtonUpdate()"
          depressed
          :color="completeColour"
          >Completed</v-btn
        >
      </v-col>
      <v-spacer :md="5" class="hidden-xs-only"></v-spacer>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColour">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Sudoku from "@/js/sudoku.js";
export default {
  computed: {},
  data: function() {
    return {
      snack: false,
      snackColour: "",
      progressColour: "",
      completeColour: "",
      snackText: "",
      max25chars: v => v.length <= 9 || "Input too long!",
      pagination: {},
      sumGroup: (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      // Silly default value to stop errors in the console
      sudoku: { problem: [] },
      headers: ["0", "1", "2", "3", "4", "5", "6", "7", "8"].map(index => {
        return {
          align: "center",
          sortable: false,
          value: index,
          width: "10%"
        };
      })
    };
  },
  methods: {
    progress() {
      this.snack = true;
      switch (this.sudoku.empty) {
        case 1:
          this.snackColour = "info";
          this.snackText = `${this.sudoku.empty} cell left`;
          break;
        case 0:
          this.isCompleteButtonUpdate();
          this.snackColour = "info";
          this.snackText = `${this.sudoku.empty} cell left`;
          break;
        default:
          this.snackText = `${this.sudoku.empty} cells left`;
      }
    },

    isCompleteButtonUpdate() {
      var complete = this.sudoku.complete ? "success" : "error";
      this.completeColour = complete;
    },
    save() {
      this.snack = true;
      this.snackColour = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColour = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColour = "info";
      this.snackText = "Dialog opened";
    },
    convertSudoku(str) {
      const tmpSudoku = [];
      str.split("\n").forEach((row, index) => {
        // This handles new lines at the end of the file
        if (index < 9) {
          tmpSudoku.push(
            Object.assign(
              {},
              row
                .split(",")
                .map(Number)
                .map(item => {
                  if (item == 0) {
                    item = "";
                  }
                  return item.toString();
                })
            )
          );
        }
      });
      return tmpSudoku;
    },
    async getSudoku(url, cache_value) {
      return await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer" // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      })
        .then(function(response) {
          if (!response.ok) {
            throw Error(response);
          }
          return response;
        })
        .then(response => response.json())
        .catch(function(error) {});
    },
    async getProblem(id) {
      const rawSudoku = await this.getSudoku(
        "https://sudokus.andrewjohnperry.com/problem?id=" + id
      );

      return this.convertSudoku(rawSudoku);
    },
    async getSolution() {
      const rawSudoku = await this.getSudoku(
        "https://sudokus.andrewjohnperry.com/solution"
      );
      return { sudoku: this.convertSudoku(rawSudoku.sudoku), id: rawSudoku.id };
    },
    async getNewProblem() {
      const solution = await this.getSolution();
      this.sudoku = new Sudoku(
        await this.getProblem(solution.id),
        solution.sudoku
      );
    }
  },
  mounted: function() {
    this.getNewProblem();
  }
};
</script>
<style>
table {
  margin: 1em auto;
}
td {
  height: 30px;
  width: 30px;
  border: 1px solid;
  text-align: center;
}
td:first-child {
  border-left: solid;
}
td:nth-child(3n) {
  border-right: solid;
}
tr:first-child {
  border-top: solid;
}
tr:nth-child(3n) td {
  border-bottom: solid;
}
</style>
