export default class Sudoku {
  constructor(problem, solution) {
    this.originalProblem = problem;
    this.problem = problem;
    this.solution = solution;
  }
  static totalCells = 81;
  // If the value is an empty string, someone has put zero in or more than one character in
  // then it is a valid cell value
  static validCellValue = v => v === "" || v === "0" || v.length > 1;
  get empty() {
    return this.countEmptyCells();
  }
  get filled() {
    return this.countCompleteCells();
  }
  get wrong() {
    return this.countWrongCells();
  }
  get complete() {
    return this.countCompleteCells() === Sudoku.totalCells;
  }
  reset() {
    this.problem = this.originalProblem;
  }
  countEmptyCells() {
    let emptyCells = 0;
    this.problem.forEach(row => {
      let problemRow = Object.values(row);
      emptyCells += problemRow.reduce((acc, val) => {
        if (Sudoku.validCellValue(val)) {
          acc++;
        }
        return acc;
      }, 0);
    });
    return emptyCells;
  }

  countWrongCells() {
    let wrongCells = 0;
    this.solution.forEach((solutionRow, columnIndex) => {
      let problemRow = Object.values(this.problem[columnIndex]);
      wrongCells += problemRow.reduce((acc, val, index) => {
        if (!(val === solutionRow[index] || Sudoku.validCellValue(val))) {
          acc++;
        }
        return acc;
      }, 0);
    });
    return wrongCells;
  }
  countCompleteCells() {
    let completeCells = 0;
    this.solution.forEach((solutionRow, columnIndex) => {
      let problemRow = Object.values(this.problem[columnIndex]);
      completeCells += problemRow.reduce((acc, val, index) => {
        if (val === solutionRow[index]) {
          acc++;
        }
        return acc;
      }, 0);
    });
    return completeCells;
  }
}
